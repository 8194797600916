footer{
    background: $black;
	position: relative; 
    padding: 2rem 0 1rem 0!important;
	border-top: 1px solid $light-grey;

	.nav-link{
		font-size: 14px;
		color: $font-color;
		padding: 0 0 1rem 0;
		

		@include media-breakpoint-up(md){
			text-align: right;
			font-size: 1rem;
		}

		&:hover{
			text-decoration: underline;
		}
	}

	.nav-item{
		&:last-child{
			.nav-link{
				padding: 0;
			}
		}
	}

	.store-btn{
		display: inline-block;

		img{
			max-height: 40px;
			width: auto;
		}
	}

	hr{
		border-top: 1px solid rgba($dark-grey, 0.5);
	}
}