@import 'variables';

//bootstrap
$grid-gutter-width: 8px;
@import '~bootstrap/scss/bootstrap';

//fonts
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

//functions
@import 'functions/rem-calc';

//core stylesheet
@import 'layout';
@import 'menu';
@import 'navigation';
@import 'typography';
@import 'button';
@import 'form';
@import 'footer';
@import 'flashMessage';
@import 'cachedDataMessage';

//plugins
@import 'plugins/timeline';
@import 'plugins/chartist';
@import 'plugins/odometer';

//components
@import 'components/chart';
@import 'components/floating-action-button';
@import 'components/longInlineLoader';
@import 'components/healthcircle';
@import 'components/product';

//page templates
@import 'pageTemplates/login';
@import 'pageTemplates/measurement';
@import 'pageTemplates/settings';

@import 'pageTemplates/infoPages';
@import 'pageTemplates/cmsContent';
@import 'pageTemplates/contact';
@import 'pageTemplates/articles';
@import 'pageTemplates/article';
@import 'pageTemplates/error';
@import 'pageTemplates/authConfirm';
@import 'pageTemplates/promoCode';
@import 'pageTemplates/quiz';
@import 'pageTemplates/coupon';
@import 'pageTemplates/newsletter-unsubscribe';
@import 'pageTemplates/cart';
@import 'pageTemplates/thankYou';
@import 'pageTemplates/update';
@import 'pageTemplates/achievements';
@import 'pageTemplates/hydration';

//utils
@import 'envIndicator';
