.page-template-cart {
    main{
        header {
            padding: 0 0 2rem 0;
    
            @include media-breakpoint-up(md) {
                padding: 2rem 0;
            }
        }
    
        section {
            padding: 0 0 1rem 0;
            
            &.with-border{
                border-bottom: 1px solid $grey;
                margin: 0 0 3rem 0;
            }

            &.with-md-border{
                @include media-breakpoint-up(md){
                    border-bottom: 1px solid $grey;
                    margin: 0 0 2rem 0;
                }
            }
        }
    
        h2 {
            font-size: 20px;
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid $grey;
            font-weight: 500;
        }

        h3{
            font-size: 14px;
            color: $font-color;
        }
    
        .btn-link{
            color: $font-color;
            font-size: 14px;
        }

        .line{
            position: relative;
            text-align: center;
            margin: 0 0 1.5rem 0;
            font-size: 14px;
            color: $grey;
            
            span{
                position: relative;
                display: inline-block;
                background: $white;
                padding: 0 1rem;
            }

            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 1px;
                background: $light-grey2;
                top: 50%;
                left: 0;
            }
        }
        
        .product-thumbnail{
            width: 80px;
            height: auto;
        }
    
        .price {
            text-align: right;
        }
    
        .payment-method-form {
            .collapse.show {
                display: inline;
            }
        }
    
        span.collapse-arrow {
            display: inline-block;
            color: $primary;
            transition: all 0.3s ease-in-out;
    
            &.open {
                transform: rotate(180deg);
            }
        }
    
        .cart-product {
            .product-row {
                border-bottom: 1px solid $light-grey2;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 14px;

                &:nth-child(2){
                    padding-top: 0;
                }

                @include media-breakpoint-up(md) {
                    align-items: center;
                }

                .product-name{
                    font-size: 14px;
                }
            }
        }
    
        .cart-summary {
            .row {
                border-bottom: 1px solid $light-grey;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 14px;
    
                &.delivery-option-price {
                    display: none;
                    border-bottom: 1px solid $primary;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }
            }

            .summary-product {
                border-bottom: 1px solid $light-grey2;
                padding-top: 1rem;
                padding-bottom: 1rem;
                font-size: 14px;

                &:nth-child(2){
                    padding-top: 0;
                }

                &:last-child{
                    border-bottom: none;
                    padding-bottom: 0;
                }

                @include media-breakpoint-up(md) {
                    align-items: center;
                }

                .product-name{
                    font-size: 14px;
                }

                .row{
                    &:nth-child(1){
                        padding-top: 0;
                    }
                }
            }

            strong{
                color: $black;
            }
        }

        .grey-box{
            background: $light-grey;
            border: 1px solid $light-grey2;
            border-radius: $borderRadius;
            margin: 0 0 1rem 0;
            padding: 1rem;
        }

        .payment-method {
            background-image: url('../../assets/icons/forward_icon.svg');
            background-position: right center;
            background-repeat: no-repeat;
            padding-right: 2rem;

            @include media-breakpoint-up(md){
                background-image: none;
                padding-right: 1rem;
            }
            
            h3{
                color: $black;
                margin-bottom: 0.25rem;
            }

            p {
                font-size: 12px;
                color: $black;
            }
    
            .payment-logo {
                max-height: 55px;
            }
        }

        .custom-control-label{
            font-size: 12px;
        }
    }
}
