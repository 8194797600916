.page-template-thnk-you {
    main{
        p{
            font-size: 1rem;

            strong{
                color: $black;
            }
        }

        .grey-box{
            background: $light-grey;
            border: 1px solid $light-grey2;
            border-radius: $borderRadius;
            margin: 0 0 1rem 0;
            padding: 1rem;
        }

        hr{
            margin: 2rem 0;
        }
    
        .store-link {
            display: inline-block;
            margin: 1rem 1rem 0 0;
        }
    }
}
